/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, {FC} from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO : FC<{
  description?: string,
  lang?: string,
  meta?: any,
  title?: string,
  published_time?: any,
  thumbnail?: any
}> = ({ description, lang, meta, title, published_time, thumbnail }) => {

  const {site} = useStaticQuery(
      graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            author {
              name
            }
            description
            social {
              twitter
            }
          }
        }
      }
    `
  )


  const imageSrc = thumbnail && thumbnail.childImageSharp.fixed.src;
  const image = site.siteMetadata?.siteUrl + imageSrc;

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s - ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `author`,
          content: site.siteMetadata?.author?.name || ``,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: image
        },
        {
          property: `og:image:width`,
          content: "1200px",
        },
        {
          property: `og:image:height`,
          content: "628px",
        },
        {
          property: `og:url`,
          content: site.siteMetadata?.siteUrl,
        },
        {
          property: `article:published_time`,
          content: published_time,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.social?.twitter || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:image:src`,
          // Set up using this approach: https://codinhood.com/micro/frontmatter-images-for-twitter-cards-gatsbyjs

          // had to use external image below. looks like problem with gatsby sharp
          // this is the same issue: https://stackoverflow.com/questions/67942759/twitter-card-images-not-working-on-gatsby-app
          // emailed Sasha and said he will make ticket with Gatsby: https://github.com/gatsbyjs/gatsby/issues/32011
          // imagePathname = "/checkmates-in-life/"
          // content: "https://raw.githubusercontent.com/colestriler/striler-blog/master/content/blog" + imagePathname + "twitterImage.jpg"
          content: image,
        },
        {
          name: `twitter:site`,
          content: site.siteMetadata?.social?.twitter || ``,
        },
        {
          name: `twitter:image:alt`,
          content: "Cole Striler's Blog",
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
