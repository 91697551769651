import React from "react"
import { Link } from "gatsby"
import SubstackForm from "../substackForm";
import Bio from "./bio";
import {FC} from "../../../types";
import {Box, Container, ContainerProps, Stack, Typography} from "@mui/material";
import Navbar from "./Navbar";

const Layout: FC<ContainerProps & {  title: string }> = (props) => {
  // const rootPath = `${__PATH_PREFIX__}/`
  // const isRootPath = location.pathname === rootPath
  // let header
  //
  // if (isRootPath) {
  //   header = (
  //     // <h1 className="main-heading">
  //     //   <Link to="/">{title} #1</Link>
  //     // </h1>
  //       <Link className="header-link-home" to="/">
  //           {/*{"← return home"}*/}
  //           {props.title}
  //       </Link>
  //   )
  // } else {
  //   header = (
  //     <Link className="header-link-home" to="/">
  //         {/*{"← return home"}*/}
  //       {props.title}
  //     </Link>
  //   )
  // }

  return (
    <Stack>
      <Navbar />
      <Container maxWidth={props.maxWidth}>
        <main>{props.children}</main>
      </Container>
        {/*<Bio />*/}
        {/*<SubstackForm />*/}
      <footer>
        <Typography textAlign="center">© {new Date().getFullYear()}, Cole Striler</Typography>
        {/*{` `}*/}
        {/*<a href="https://www.gatsbyjs.com">Gatsby</a>*/}
      </footer>
    </Stack>
  )
}

export default Layout
