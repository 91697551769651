import React from "react"
import {Link} from "gatsby"
import SubstackForm from "../substackForm";
import Bio from "./bio";
import {FC} from "../../../types";
import {
  AppBar, Avatar,
  Box,
  Button,
  Container,
  ContainerProps,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from "@mui/material";
import {styled, alpha} from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AdbIcon from '@mui/icons-material/Adb';

// const pages = ['Products', 'Pricing', 'Blog'];
// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const Navbar: FC<ContainerProps> = (props) => {

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar
      position="static"
      // sx={{ backgroundColor: '#08125f'}}
      sx={{
        marginBottom: 3,
        backgroundColor: '#f7f9fa',
        boxShadow: 'none'
      }}
    >
      <Container maxWidth="md">
        <Toolbar disableGutters>
          {/*<AdbIcon sx={{display: {xs: 'none', md: 'flex'}, mr: 1}}/>*/}
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: {xs: 'none', md: 'flex'},
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              // color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Cole Striler
          </Typography>

          {/*<Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>*/}
          {/*  <IconButton*/}
          {/*    size="large"*/}
          {/*    aria-label="account of current user"*/}
          {/*    aria-controls="menu-appbar"*/}
          {/*    aria-haspopup="true"*/}
          {/*    onClick={handleOpenNavMenu}*/}
          {/*    color="inherit"*/}
          {/*  >*/}
          {/*    <MenuIcon/>*/}
          {/*  </IconButton>*/}
          {/*  <Menu*/}
          {/*    id="menu-appbar"*/}
          {/*    anchorEl={anchorElNav}*/}
          {/*    anchorOrigin={{*/}
          {/*      vertical: 'bottom',*/}
          {/*      horizontal: 'left',*/}
          {/*    }}*/}
          {/*    keepMounted*/}
          {/*    transformOrigin={{*/}
          {/*      vertical: 'top',*/}
          {/*      horizontal: 'left',*/}
          {/*    }}*/}
          {/*    open={Boolean(anchorElNav)}*/}
          {/*    onClose={handleCloseNavMenu}*/}
          {/*    sx={{*/}
          {/*      display: {xs: 'block', md: 'none'},*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    {pages.map((page) => (*/}
          {/*      <MenuItem key={page} onClick={handleCloseNavMenu}>*/}
          {/*        <Typography textAlign="center">{page}</Typography>*/}
          {/*      </MenuItem>*/}
          {/*    ))}*/}
          {/*  </Menu>*/}
          {/*</Box>*/}
          {/*<AdbIcon sx={{display: {xs: 'flex', md: 'none'}, mr: 1}}/>*/}
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: {xs: 'flex', md: 'none'},
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              // color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Cole Striler
          </Typography>
          {/*<Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>*/}
          {/*  {pages.map((page) => (*/}
          {/*    <Button*/}
          {/*      key={page}*/}
          {/*      onClick={handleCloseNavMenu}*/}
          {/*      sx={{my: 2, color: 'white', display: 'block'}}*/}
          {/*    >*/}
          {/*      {page}*/}
          {/*    </Button>*/}
          {/*  ))}*/}
          {/*</Box>*/}

          {/*<Box sx={{flexGrow: 0}}>*/}
          {/*  <Tooltip title="Open settings">*/}
          {/*    <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>*/}
          {/*      <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg"/>*/}
          {/*    </IconButton>*/}
          {/*  </Tooltip>*/}
          {/*  <Menu*/}
          {/*    sx={{mt: '45px'}}*/}
          {/*    id="menu-appbar"*/}
          {/*    anchorEl={anchorElUser}*/}
          {/*    anchorOrigin={{*/}
          {/*      vertical: 'top',*/}
          {/*      horizontal: 'right',*/}
          {/*    }}*/}
          {/*    keepMounted*/}
          {/*    transformOrigin={{*/}
          {/*      vertical: 'top',*/}
          {/*      horizontal: 'right',*/}
          {/*    }}*/}
          {/*    open={Boolean(anchorElUser)}*/}
          {/*    onClose={handleCloseUserMenu}*/}
          {/*  >*/}
          {/*    {settings.map((setting) => (*/}
          {/*      <MenuItem key={setting} onClick={handleCloseUserMenu}>*/}
          {/*        <Typography textAlign="center">{setting}</Typography>*/}
          {/*      </MenuItem>*/}
          {/*    ))}*/}
          {/*  </Menu>*/}
          {/*</Box>*/}
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default Navbar
